
// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store/index";

// Vendors
import moment from "moment";

// Models
import {AppPlayerHealthRecordsRootListIndex} from "@/models/app/player/health-records/root/list/index";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import AppPlayerHealthRecordsRootListFormCreateIndexVue from "@/views/app/player/health-records/root/list/form/create.vue";
import AppPlayerHealthRecordsRootListFormUpdateIndexVue from "@/views/app/player/health-records/root/list/form/update.vue";
import AppPlayerHealthRecordsRootListFormRemoveIndexVue from "@/views/app/player/health-records/root/list/form/remove.vue";
import AppPlayerHealthRecordsRootListFormLookupDocumentIndexVue from "@/views/app/player/health-records/root/list/form/lookup-document.vue";
import AppPlayerHealthRecordsRootListFormUploadDocumentIndexVue from "@/views/app/player/health-records/root/list/form/upload-document.vue";

// Services
import {getRequest} from "@/services/api/request";

@Options({
  components: {
    ElRow,
    ElCol,
    AppPlayerHealthRecordsRootListFormCreateIndexVue,
    AppPlayerHealthRecordsRootListFormUpdateIndexVue,
    AppPlayerHealthRecordsRootListFormRemoveIndexVue,
    AppPlayerHealthRecordsRootListFormLookupDocumentIndexVue,
    AppPlayerHealthRecordsRootListFormUploadDocumentIndexVue,
  },
})
export default class AppPlayerHealthRecordsRootListIndexVue extends Vue {
  isLoading: boolean | null = false;

  formData: any = {
    filter: {
      user_id: store.getters.getAuthentication.id,
    },
    organization_id: store.getters.getOrganizationMember.organization_id,
  };

  moment: any = null;

  healthRecords: Array<AppPlayerHealthRecordsRootListIndex> = [];

  async getHealthRecords(): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `/common/health-records`,
      formData: this.formData,
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.healthRecords = r.data;
      }
    });
    this.isLoading = false;
  }

  async created(): Promise<void> {
    this.moment = moment;
    await this.getHealthRecords();
  }
}
